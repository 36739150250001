import Vue from 'vue'
import Vuex from 'vuex'
import { Exhibitor, Guest, News, PageConfig, RawGuest, Sponsor, Ticket } from '@/constants/types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootFontSize: 0,
    kvPlayState: true,
    statusBarHeight: 0,
    mid: '',
    mainBtnInfo: {},
    pageConfig: {} as PageConfig,
    showTopBtn: false,
    mainBtnTitle: '',
    searchKeyword: '',
    searching: false,
    activeMenuTitle: '',
    manualActiveMenuTitle: '',
    sponsorList: [] as Sponsor[],
    allNewsList: [] as News[],
    rawGuestList: [] as RawGuest[],
    allGuestList: [] as Guest[],
    groupGuestMap: new Map() as Map<number, Guest[]>,
    singleGuestList: [] as Guest[],
    groupGuestList: [] as Guest[],
    exhibitorList: [] as Exhibitor[],
    ticketList: [] as Ticket[]
  },
  getters: {},
  mutations: {
    setRootFontSize(state, fontSize) {
      state.rootFontSize = fontSize
    },
    setKvPlayState(state, kvPlayState) {
      state.kvPlayState = kvPlayState
    },
    setStatusBarHeight(state, statusBarHeight) {
      state.statusBarHeight = statusBarHeight
    },
    setMid(state, mid: string) {
      state.mid = mid
    },
    setMainBtnInfo(state, mainBtnInfo) {
      state.mainBtnInfo = mainBtnInfo
    },
    setPageConfig(state, pageConfig: PageConfig) {
      state.pageConfig = pageConfig
    },
    setShowTopBtn(state, showTopBtn: boolean) {
      state.showTopBtn = showTopBtn
    },
    setMainBtnTitle(state, title) {
      state.mainBtnTitle = title
    },
    setSearchKeyword: (state, keyword: string) => {
      state.searchKeyword = keyword
    },
    setSearching: (state, searching: boolean) => {
      state.searching = searching
    },
    setActiveMenuTitle: (state, activeMenuTitle: string) => {
      state.activeMenuTitle = activeMenuTitle
    },
    setManualActiveMenuTitle: (state, activeMenuTitle: string) => {
      state.manualActiveMenuTitle = activeMenuTitle
    },
    setSponsorList: (state, list: Sponsor[]) => {
      state.sponsorList = list
    },
    setRawGuestList: (state, rawGuestList: RawGuest[]) => {
      state.rawGuestList = rawGuestList
    },
    setAllNewsList: (state, newsList: News[]) => {
      state.allNewsList = newsList
    },
    setAllGuestList: (state, guestList: Guest[]) => {
      state.allGuestList = guestList
    },
    setGroupGuestMap: (state, map: Map<number, Guest[]>) => {
      state.groupGuestMap = map
    },
    setSingleGuestList: (state, guestList: Guest[]) => {
      state.singleGuestList = guestList
    },
    setGroupGuestList: (state, guestList: Guest[]) => {
      state.groupGuestList = guestList
    },
    setExhibitorList: (state, exhibitorList: Exhibitor[]) => {
      state.exhibitorList = exhibitorList
    },
    setTicketList: (state, ticketList: Ticket[]) => {
      state.ticketList = ticketList
    }
  },
  actions: {},
  modules: {}
})
