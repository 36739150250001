import { getContainerInfo } from '@bilibili-studio/h5-utils'
import { getRealPx } from '@/commonLogic/adaptViewport'
import store from '@/store'

let containerInfo: any = null

export async function useContainerInfo() {
  if (containerInfo === null) {
    try {
      containerInfo = await getContainerInfo()
    } catch (e) {
      console.log(e)
    }
  }
  return containerInfo
}

async function setStatusBarHeight() {
  const containerInfo = await useContainerInfo()
  const dpr = window.devicePixelRatio
  const height = (containerInfo?.statusBarHeight ?? 0) / dpr + (getRealPx(10) || 0)
  store.commit('setStatusBarHeight', height)
}

setStatusBarHeight()
