//bw官号mid
export const bwId = 402923390

// 嘉宾列表
export const guestSourceId = '664bffe1a10cfc98a4d12725'
// 品牌赞助
export const sponsorSourceId = '664c001da7f5996dbb2977a1'
// 活动新闻
export const newsSourceId = '664bfffca7f5996dbb2977a0'
// 门票
export const ticketSourceId = '6647692fa7f5996dbb297757'
// 展商
export const exhibitorSourceId = '664bffc9a10cfc98a4d12724'
// 综合配置
export const configSourceId = '66418e5771c17e1a486141c4'

export const moduleList = [
  {
    title: '品牌赞助',
    subtitle: '感谢各位的支持',
    getIcon: () => import('@/assets/common/images/module-title-0.svg')
  },
  {
    title: '活动新闻',
    subtitle: '关注活动最新消息',
    getIcon: () => import('@/assets/common/images/module-title-1.svg')
  },
  {
    title: '活动介绍',
    subtitle: '朋友  BW了解一下',
    getIcon: () => import('@/assets/common/images/module-title-2.svg')
  },
  {
    title: '票务信息',
    subtitle: '购票注意事项',
    getIcon: () => import('@/assets/common/images/module-title-3.svg')
  },
  {
    title: '场地信息',
    subtitle: '关注地图   不迷路',
    getIcon: () => import('@/assets/common/images/module-title-4.svg')
  },
  {
    title: '嘉宾介绍',
    subtitle: '嘉宾排名不分先后',
    getIcon: () => import('@/assets/common/images/module-title-5.svg')
  },
  {
    title: '展会内容',
    subtitle: '分区情报解锁',
    getIcon: () => import('@/assets/common/images/module-title-6.svg')
  },
  {
    title: '品牌合作',
    subtitle: '感谢各位的支持',
    getIcon: () => import('@/assets/common/images/module-title-7.svg')
  },
  {
    title: '动态热议',
    subtitle: '相关话题讨论',
    getIcon: () => import('@/assets/common/images/module-title-8.svg')
  }
]

// export const menuList = ['活动新闻', '活动介绍', '票务信息', '场地信息', '嘉宾介绍', '展会内容', '品牌合作']

export const menuList = moduleList.map((item) => item.title).filter((item) => item !== '品牌赞助')

export const guestType = [
  {
    label: '我的关注',
    value: 0
  },
  {
    label: '海外嘉宾',
    value: 1
  },
  {
    label: '特邀嘉宾',
    value: 2
  },
  {
    label: '国配嘉宾',
    value: 3
  },
  {
    label: 'UP主',
    value: 4
  },
  {
    label: '虚拟嘉宾',
    value: 5
  },
  {
    label: '主持人',
    value: 6
  }
]

export const dateList = [
  { date: '7.12', style: { color: '#43BE92', backgroundColor: '#AFEED0' } },
  { date: '7.13', style: { color: '#E9A472', backgroundColor: '#F9E1A5' } },
  { date: '7.14', style: { color: '#7F99E1', backgroundColor: '#C0D6F7' } }
]

/*export const ticketList = [
  {
    name: 'VIP门票',
    icon: 'ticket-title-0',
    class: 'ticket-title__0',
    borderBg: 'linear-gradient(to bottom, rgba(199, 198, 215, 0.2), #c7c6d7)',
    contentWrapBg: 'linear-gradient(to bottom, #f8f8ff, #fff7ee)',
    contentBg: 'linear-gradient(to bottom, rgba(255, 255, 255, 1) 60%,  rgba(255, 255, 255, 0.1))',
    wordDecoColor: isPC ? 'rgba(212, 173, 127, 0.15)' : 'rgba(92,90,119, 0.1)',
    flex: isPC ? 260 / 980 : 97 / 351
  },
  {
    name: 'IP典藏门票',
    icon: 'ticket-title-1',
    class: 'ticket-title__1',
    borderBg:
      'linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(255,255,255,0.2)),linear-gradient(to bottom, #FFF3F7, #FFD8E6)',
    contentWrapBg: 'linear-gradient(to bottom, #FFF3F7, #FFD8E6)',
    contentBg:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0) , rgba(255, 255, 255, 1) 24%,  rgba(255, 255, 255, 1) 60%,  rgba(255, 255, 255, 0.1))',
    wordDecoColor: 'rgba(255,32,132, 0.1)',
    flex: isPC ? 270 / 980 : 97 / 351
  },
  {
    name: 'BML联票',
    icon: 'ticket-title-2',
    class: 'ticket-title__2',
    borderBg: 'linear-gradient(to bottom, rgba(221,243,252, 0), #DDF3FC)',
    contentWrapBg: 'linear-gradient(to bottom, #F1FBFF, #C4D6F0)',
    wordDecoColor: 'rgba(153,153,153, 0.1)',
    flex: isPC ? 270 / 980 : 97 / 351
  },
  {
    name: '普通门票',
    icon: 'ticket-title-3',
    class: 'ticket-title__3',
    borderBg: 'linear-gradient(to bottom, rgba(221,243,252, 0), #DDF3FC)',
    contentWrapBg: 'linear-gradient(to bottom, #F1FBFF, #C4D6F0)',
    contentBg:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.1) , rgba(255, 255, 255, 0.5) 30%,  rgba(255, 255, 255, 0.6) 60%,  rgba(255, 255, 255, 0.1))',
    wordDecoColor: 'rgba(153,153,153, 0.1)',
    flex: isPC ? 180 / 980 : 60 / 351
  }
]*/

// 轮播图时间
export const swiperAutoplayTime = 2000

export const zoningList = [
  {
    name: 'UP空间',
    value: '0',
    getIcon: () => import('@/assets/common/images/zoning-title-0.svg'),
    map: ['https://i0.hdslb.com/bfs/activity-plat/static/20240519/4920177e4bf9eb2b0fd8dacba0e5dbf8/tuoC3zvQip.png']
  },
  {
    name: '遇见动漫',
    value: '1',
    getIcon: () => import('@/assets/common/images/zoning-title-1.svg'),
    map: ['https://i0.hdslb.com/bfs/activity-plat/static/20240519/4920177e4bf9eb2b0fd8dacba0e5dbf8/GFTsc4PM51.png']
  },
  {
    name: '模魂英雄',
    value: '2',
    getIcon: () => import('@/assets/common/images/zoning-title-2.svg'),
    map: ['https://i0.hdslb.com/bfs/activity-plat/static/20240519/4920177e4bf9eb2b0fd8dacba0e5dbf8/9c0vwiRIvV.png']
  },
  {
    name: '虚拟乐园',
    value: '3',
    getIcon: () => import('@/assets/common/images/zoning-title-3.svg'),
    map: ['https://i0.hdslb.com/bfs/activity-plat/static/20240519/4920177e4bf9eb2b0fd8dacba0e5dbf8/i002ECUNQ9.png']
  },
  {
    name: '游戏世界',
    value: '4',
    getIcon: (index: number) => {
      switch (index) {
        case -1:
          return import('@/assets/common/images/zoning-title-4.svg')
        case 0:
          return import('@/assets/common/images/zoning-title-4-1.svg')
        case 1:
          return import('@/assets/common/images/zoning-title-4-2.svg')
      }
    },
    map: [
      'https://i0.hdslb.com/bfs/activity-plat/static/20240519/4920177e4bf9eb2b0fd8dacba0e5dbf8/aJSy6rAodC.png',
      'https://i0.hdslb.com/bfs/activity-plat/static/20240519/4920177e4bf9eb2b0fd8dacba0e5dbf8/eLfwc6rDCO.png'
    ]
  },
  {
    name: '恋恋心声',
    value: '5',
    getIcon: () => import('@/assets/common/images/zoning-title-5.svg'),
    map: ['https://i0.hdslb.com/bfs/activity-plat/static/20240519/4920177e4bf9eb2b0fd8dacba0e5dbf8/beUBeM9A6H.png']
  },
  {
    name: '梦幻集市',
    value: '6',
    getIcon: () => import('@/assets/common/images/zoning-title-6.svg'),
    map: ['https://i0.hdslb.com/bfs/activity-plat/static/20240524/4920177e4bf9eb2b0fd8dacba0e5dbf8/6CItNT9Cta.png']
  },
  {
    name: '一起桌游',
    value: '7',
    getIcon: () => import('@/assets/common/images/zoning-title-7.svg'),
    map: ['https://i0.hdslb.com/bfs/activity-plat/static/20240519/4920177e4bf9eb2b0fd8dacba0e5dbf8/fZ5zG7ocPA.png']
  },
  {
    name: '痛无止境',
    value: '8',
    getIcon: () => import('@/assets/common/images/zoning-title-8.svg'),
    map: ['https://i0.hdslb.com/bfs/activity-plat/static/20240524/4920177e4bf9eb2b0fd8dacba0e5dbf8/6aMJ1ae51u.png']
  }
]
