// 洗牌算法，打乱数组
import { closeWebview, isIOS, openScheme, openSpacePage } from '@bilibili-studio/h5-utils'
import store from '@/store'
import toast from '@bilibili/vanilla-toast'
import router from '@/router'
import logger from '@plat-components/utils2/_esm5/loggerV2'
import { biliBridge, isSupport } from '@bilibili/js-bridge'

export function shuffle<T>(array: T[]): T[] {
  const newArray = array.slice() // 创建数组的副本
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)) // 随机选择一个索引
    // 交换元素
    const temp = newArray[i]
    newArray[i] = newArray[j]
    newArray[j] = temp
  }
  return newArray // 返回打乱后的数组
}

export const isPC = (() => {
  const userAgent = navigator.userAgent
  const isMobile =
    /Android|webOS|iPhone|ipad|iPod|iOS|BlackBerry|Windows Phone|Tablet|Mobile HuaweiBrowser|AppleWebKit.*Mobile.*/i.test(
      userAgent
    )
  return !isMobile
})()

export const openUserSpace = (mid: number) => {
  if (isPC) {
    window.open(`https://space.bilibili.com/${mid}`, '_blank')
  } else {
    openSpacePage(mid)
  }
}

export const openUrl = (url: string) => {
  if (url) {
    if (isPC) {
      window.open(url, '_blank')
    } else {
      openScheme(url, '', '_blank')
    }
  } else {
    toast.info('活动暂未开始～')
  }
}

export const openBml = () => {
  const bmlUrl = store.state?.pageConfig?.bml_url ?? ''
  logger('click', 'bml')
  openUrl(bmlUrl)
}

export const goToBack_h5 = () => {
  if (window.history.length < 2) {
    // 当前处于最底层，直接关闭webview
    closeWebview()
  } else {
    const preHref = window.location.href
    router.go(-1)
    setTimeout(() => {
      const curHref = window.location.href
      if (preHref === curHref) {
        closeWebview()
      }
    }, 200)
  }
}

export const recordDuration = () => {
  const startTime = new Date().getTime()
  if (isIOS) {
    isSupport('ui.observeContainerStatus').then((support) => {
      if (support) {
        biliBridge.callNative({
          method: 'ui.observeContainerStatus',
          onStatusChange: (info: any) => {
            if (info.status === 'hide') {
              const endTime = new Date().getTime()
              const duration = endTime - startTime
              logger('show', 'duration', { duration })
            }
          }
        })
      }
    })
  } else {
    window.addEventListener('beforeunload', function () {
      const endTime = new Date().getTime()
      const duration = endTime - startTime
      logger('show', 'duration', { duration })
    })
  }
}

recordDuration()
