import { isPC } from '@/utils'
import Vue from 'vue'
import store from '@/store'
import { inBiliApp } from '@bilibili/js-bridge'
import { goLogin, openInApp } from '@/commonLogic/clickCheck'

Vue.directive('device-class', {
  bind: (el) => {
    el.classList.add(isPC ? 'pc' : 'h5')
  }
})

// 某些按钮的行为需要登录态，这里校验登录态，如果未登录跳转登录页面
Vue.directive('check-click', {
  bind: async function (el, { value, arg }) {
    el.onclick = (event) => {
      if (!isPC && !inBiliApp) {
        openInApp()
      } else {
        const isLogin = !!store.state.mid
        if (!isLogin) {
          goLogin()
        } else {
          value()
        }
      }
      if (arg === 'stop') {
        event.stopPropagation()
      }
    }
  }
})
