import { isPC } from '@/utils'
import store from '@/store'

const rootValue = 100

const designWidth_PC = 1920
const designWidth_H5 = 375
const designWidth = isPC ? designWidth_PC : designWidth_H5

const maxViewportWidth = isPC ? 2000 : 600
const viewportWidth = () => (isPC ? window.innerWidth : Math.min(maxViewportWidth, window.innerWidth))

const targetWidth = () => (isPC ? viewportWidth() * 1.2 : viewportWidth())

export const resetFontSize = () => {
  const value =
    (isPC ? (viewportWidth() <= 1440 ? Math.max(1080, targetWidth()) / designWidth : 1) : targetWidth() / designWidth) *
    rootValue
  document.documentElement.style.fontSize = `${value}px`
  store.commit('setRootFontSize', value)
}

window.addEventListener('resize', resetFontSize)

export const getRealPx = (px: number) => {
  const currentRootValue = parseInt(`${document.documentElement.style.fontSize}`.replace('px', ''), 10)
  const realPx = (currentRootValue / rootValue) * px
  return realPx || px
}
