import Vue from 'vue'
import router from './router'
import store from './store'
import { disableScrollBounces, isIE } from '@bilibili-studio/h5-utils'
import { ReportPlugin } from '@bilibili-studio/h5-plugins'
import './assets/styles/app_base_style.scss'
import { initSvgIconAssets } from '@/commonLogic/initSvgIconAssets'
import { initEnv } from '@bilibili/js-bridge'
import { goToBack_h5, isPC } from '@/utils'
import '@/commonLogic/directives'
import '@/commonLogic/config'
import '@/commonLogic/clickCheck'
import '@/commonLogic/useContainerInfo'
import '@/commonLogic/sponsor'
import '@/commonLogic/news'
import '@/commonLogic/guest'
import VueLazyload from 'vue-lazyload'
import smoothscroll from 'smoothscroll-polyfill'
import { resetFontSize } from '@/commonLogic/adaptViewport'

if (isIE) {
  location.href = 'https://www.bilibili.com/blackboard/fe/activity-CjJbuaD7Xw.html'
}

Vue.config.productionTip = false
initEnv()
disableScrollBounces()
initSvgIconAssets()

Vue.use(VueLazyload, {
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0
  },
  // loading: 'https://i0.hdslb.com/bfs/face/member/noface.jpg',
  error: 'https://i0.hdslb.com/bfs/face/member/noface.jpg'
})

Vue.use(ReportPlugin)

try {
  smoothscroll.polyfill()
  // const App = Vue.component('async-main-app', () => (isPC ? import('./App_pc.vue') : import('./App_h5.vue')))
  const AppPromise = () => (isPC ? import('./App_pc.vue') : import('./App_h5.vue'))
  const App = (await AppPromise()).default

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
} catch {
  confirm('非常抱歉，当前浏览器或系统版本过低，无法正常访问该页面～')
  goToBack_h5()
}

resetFontSize()
