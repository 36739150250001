import { biliBridge, inBiliApp } from '@bilibili/js-bridge'
import { openScheme } from '@bilibili-studio/h5-utils'
import { isPC } from '@/utils'
import { openApp } from '@bilibili/openapp-lib'
import { fetchUserInfo } from '@/api'
import store from '@/store'
import { initOAuthSDK } from '@bilibili/openapp-lib'
initOAuthSDK({
  debug: false,
  appId: 'wx8be72568ff217c00', // 服务号appId
  timestamp: new Date().getTime(),
  signature: 'cca8fff6afdee4ce7f20c6ad605b3725bc2c5ad6', // 签名
  jsApiList: [], // 需要使用的JS接口列表
  openTagList: ['wx-open-launch-app'] // 需要使用的开放标签列
})

const url = location.href
const scheme = `bilibili://browser?url=${url}`

const initUserInfo = () => {
  fetchUserInfo().then((res) => {
    if (res.code === 0 && res.data) {
      const data = res.data
      if (data?.isLogin) {
        store.commit('setMid', data?.mid)
      }
    }
  })
}
initUserInfo()

export const openInApp = () => {
  try {
    const options = {
      schema: scheme,
      universalLink: url,
      timeout: 3000
    }
    openApp(options)
  } catch {
    //
  }
}

export const goLogin = (callback?: () => void, fallback?: () => void) => {
  if (isPC) {
    const script = document.createElement('script')
    if (window.location.href.indexOf('pre') >= 0) {
      script.src = `//pre-s1.hdslb.com/bfs/seed/jinkela/short/mini-login-v2/miniLogin.umd.min.js`
    } else {
      script.src = `//s1.hdslb.com/bfs/seed/jinkela/short/mini-login-v2/miniLogin.umd.min.js`
    }

    script.addEventListener('load', () => {
      const miniLogin = new window.MiniLogin({
        origin: 'studio_pc'
      })
      miniLogin.showComponent({
        spm_id_from: '459.0.0.0'
      })
      miniLogin.addEventListener('success', () => {
        // 登录成功
        initUserInfo()
      })
    })
    document.body.appendChild(script)
  } else {
    if (inBiliApp) {
      biliBridge.callNative({
        method: 'auth.login',
        data: {
          type: 'default'
        },
        onLogin: (data: any) => {
          if (data.state) {
            if (callback) {
              callback()
              initUserInfo()
            }
          } else if (fallback) {
            fallback()
          }
        }
      })
    } else {
      location.href = `https://passport.bilibili.com/login?gourl=${url}`
    }
  }
}
