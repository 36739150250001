import { HttpService } from '@bilibili/http-svc'
import { HttpSvcRuntime, HttpSvcCsrf, HttpSvcBody, HttpSvcAppFetch } from '@bilibili/app-fetch'
import '@bilibili/http-svc/polyfill'
import toast from '@bilibili/vanilla-toast'
import store from '@/store'
import { goLogin } from '@/commonLogic/clickCheck'
import { bwId } from '@/constants'

const requestDomain = {
  api: '//api.bilibili.com',
  show: '//show.bilibili.com',
  activity: '//activity.hdslb.com'
}

export const httpSvc = new HttpService({
  fetch: new HttpSvcAppFetch(),
  middlewares: [new HttpSvcRuntime(), new HttpSvcCsrf(), new HttpSvcBody()]
})

export const fetchUserInfo = () => {
  return httpSvc.request({
    url: `${requestDomain.api}/x/web-interface/nav`,
    method: 'GET'
  })
}

export const fetchRelation = (fid = bwId) => {
  return httpSvc
    .request({
      url: `${requestDomain.api}/x/relation`,
      method: 'GET',
      params: { fid }
    })
    .then((res) => {
      return !!res?.data?.attribute
    })
    .catch(() => {
      return false
    })
}

export const followByMid = (fid = bwId, act = 1) => {
  return httpSvc
    .request({
      url: `${requestDomain.api}/x/relation/modify`,
      method: 'POST',
      params: { fid, act }
    })
    .then(() => {
      toast.info('关注成功')
    })
    .catch(() => {
      toast.info('网络异常，请重试')
    })
}

export const fetchGuestData = (pn: number, ps = 20) => {
  return httpSvc.request({
    url: `${requestDomain.api}/x/activity/operation/list`,
    method: 'GET',
    params: { source_id: '6639cd0f962d49513fe41f08', ps, pn }
  })
}

export const fetchDataBySourceId = (id: string, pn = 1, ps = 50) => {
  return httpSvc.request({
    url: `${requestDomain.api}/x/activity/operation/list`,
    method: 'GET',
    params: {
      source_id: id,
      ps,
      pn
    }
  })
}

export const reserveTicket = () => {
  const id = store.state.pageConfig?.ticket_id
  if (id) {
    return httpSvc
      .request({
        url: `${requestDomain.show}/api/ticket/user/addWish`,
        method: 'POST',
        data: { item_id: id }
      })
      .then((res) => {
        if (res.code === 0 || res.errno === 0) {
          toast.info('预约成功')
          return true
        } else if (res.errno === 2) {
          goLogin()
          return
        } else if (res.errno === 100011) {
          toast.info('预约时间还没到哦～')
        } else {
          toast.info(res.message || res.msg || '网络异常，请重试')
        }
      })
      .catch(() => {
        toast.info('网络异常，请重试')
      })
  } else {
    toast.info('预约还未开放哦～')
  }
}

export const getTicketReserve = async () => {
  const id = store.state.pageConfig?.ticket_id
  if (id) {
    return await httpSvc
      .request({
        url: `${requestDomain.show}/api/ticket/project/favCount`,
        method: 'GET',
        params: {
          item_id: id
        }
      })
      .then((res) => {
        return res.data
      })
  } else {
    return
  }
}

export const reserveLive = () => {
  const id = store.state.pageConfig?.live_id
  if (id) {
    return httpSvc
      .request({
        url: `${requestDomain.api}/x/activity/reserve`,
        method: 'POST',
        params: { sid: id }
      })
      .then((res) => {
        if (res.code === 0) {
          toast.info('预约成功')
          return true
        } else {
          toast.info(res.message || res.msg || '网络异常，请重试')
        }
      })
      .catch(() => {
        toast.info('网络异常，请重试')
      })
  } else {
    toast.info('预约还未开放哦～')
  }
}

export const getLiveReserve = async () => {
  const id = store.state.pageConfig?.live_id
  if (id) {
    return await httpSvc
      .request({
        url: `${requestDomain.api}/x/activity/reserve/following`,
        method: 'GET',
        params: {
          sid: id
        }
      })
      .then((res) => {
        return res.data
      })
  } else {
    return
  }
}
