import { fetchDataBySourceId } from '@/api'
import { configSourceId } from '@/constants'
import store from '@/store'
import { PageConfig } from '@/constants/types'

fetchDataBySourceId(configSourceId).then((res) => {
  const rawList = res?.data?.list ?? []
  if (rawList.length) {
    const config = rawList[0]?.properties as PageConfig
    if (config) {
      store.commit('setPageConfig', config)
    }
  }
})
