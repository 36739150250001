import { Guest, RawGuest } from '@/constants/types'
import { fetchDataBySourceId } from '@/api'
import { isPC } from '@/utils'
import { shuffle } from '@/utils'
import { dateList, guestSourceId } from '@/constants'
import store from '@/store'

const tranRawGuestList = (rawList: RawGuest[]) => {
  const list: Guest[] = []
  const map: Map<number, Guest[]> = new Map()
  const singleGuestList: Guest[] = []
  const groupGuestList: Guest[] = []
  rawList.forEach(({ object, properties }) => {
    const { is_follow, mid, name: nickname, face } = object
    const { name, cover, is_group, date_1, date_2, date_3, type } = properties
    const date_list = []
    if (Number(date_1)) {
      date_list.push(dateList[0])
    }
    if (Number(date_2)) {
      date_list.push(dateList[1])
    }
    if (Number(date_3)) {
      date_list.push(dateList[2])
    }
    const typeNum = Number(type)
    const item = {
      mid,
      nickname,
      face,
      is_follow,
      name: name || nickname,
      cover: cover || face,
      is_group: !!Number(is_group),
      type: typeNum,
      date_list
    }
    list.push(item)
    if (item.is_group) {
      groupGuestList.push(item)
    } else {
      singleGuestList.push(item)
    }
    const mapList = map.get(typeNum)
    if (mapList) {
      map.set(typeNum, [...mapList, item])
    } else {
      map.set(typeNum, [item])
    }
    //我的关注
    if (item.is_follow && mid !== 1) {
      const followKey = 0
      const mapList = map.get(followKey)
      if (mapList) {
        map.set(followKey, [...mapList, item])
      } else {
        map.set(followKey, [item])
      }
    }
  })
  return { list, map, singleGuestList, groupGuestList }
}

export const fetchAllGuestData = () =>
  new Promise(
    (
      resolve: (res: {
        groupGuestList: Guest[]
        list: Guest[]
        map: Map<number, Guest[]>
        singleGuestList: Guest[]
      }) => void,
      reject
    ) => {
      const guestFetchList = []
      for (let i = 1; i <= 25; i++) {
        guestFetchList.push(fetchDataBySourceId(guestSourceId, i))
      }
      try {
        Promise.all(guestFetchList).then((values) => {
          const rawList: RawGuest[] = []
          values.forEach((value) => {
            const list = value?.data?.list
            if (list) {
              rawList.push(...list)
            }
          })
          store.commit('setRawGuestList', rawList)
          resolve(tranRawGuestList(rawList))
        })
      } catch {
        reject('嘉宾列表请求失败')
      }
    }
  )

const saveGuestData = (res: {
  groupGuestList: Guest[]
  list: Guest[]
  map: Map<number, Guest[]>
  singleGuestList: Guest[]
}) => {
  const { list: allGuestList, map: groupGuestMap, singleGuestList, groupGuestList } = res
  store.commit('setAllGuestList', allGuestList)
  store.commit('setGroupGuestMap', groupGuestMap)
  store.commit('setSingleGuestList', singleGuestList)
  store.commit('setGroupGuestList', groupGuestList)
}

fetchAllGuestData().then((res) => {
  saveGuestData(res)
})

export const updateFollowState = (mid: number) => {
  const rawList = store.state.rawGuestList
  const index = rawList.findIndex((item) => item?.object?.mid === mid && !item?.object?.is_follow)
  rawList[index].object.is_follow = true
  if (rawList.find((item) => item?.object?.mid === mid && !item?.object?.is_follow)) {
    updateFollowState(mid)
  } else {
    const res = tranRawGuestList(rawList)
    saveGuestData(res)
    store.commit('setRawGuestList', rawList)
  }
}

// 随机
export const getRandomGuestList = () => {
  const singleGuestList = store.state.singleGuestList
  const groupGuestList = store.state.groupGuestList
  const list: Guest[] = []
  if (singleGuestList.length && groupGuestList.length) {
    let randomSingleGuestList = shuffle(singleGuestList)
    let randomGroupGuestList = shuffle(groupGuestList)
    let singleIndex = 0
    let groupIndex = 0
    const layout = isPC
      ? ['group', 'group', 'single', 'single', 'single', 'single']
      : ['single', 'single', 'single', 'single', 'group', 'group', 'single']
    for (let i = 0; i < 20; i++) {
      layout.forEach((type) => {
        if (type === 'group') {
          list.push(randomGroupGuestList[groupIndex])
          groupIndex++
          if (groupIndex >= randomGroupGuestList.length) {
            randomGroupGuestList = shuffle(groupGuestList)
            groupIndex = 0
          }
        } else {
          list.push(randomSingleGuestList[singleIndex])
          singleIndex++
          if (singleIndex >= randomSingleGuestList.length) {
            randomSingleGuestList = shuffle(singleGuestList)
            singleIndex = 0
          }
        }
      })
    }
  }
  return list
}

// 搜索
export const getMatchList = (keyword: string) => {
  const allGuestList = store.state.allGuestList
  const resultList: Guest[] = []
  const keywordCharArray = removeDuplicates(keyword.toLowerCase().replace(/\s+/g, '').split(''))
  if (!keywordCharArray.length) {
    return []
  }
  allGuestList.forEach((guest) => {
    let isMatch = true
    const name = guest.name.toLowerCase()
    for (let i = 0; i < keywordCharArray.length; i++) {
      if (!name.includes(keywordCharArray[i])) {
        isMatch = false
        break
      }
    }
    if (isMatch) {
      resultList.push(guest)
    }
  })
  return resultList
}

const removeDuplicates = (arr: string[]): string[] => {
  const uniqueSet = new Set(arr)
  return Array.from(uniqueSet)
}

export const textToHtml = (keyword: string, str: string) => {
  if (keyword.trim()) {
    const keywordCharArray = removeDuplicates(keyword.trim().split(''))
    const charArray = str.split('')
    let htmlStr = ''
    for (let i = 0; i < charArray.length; i++) {
      if (keywordCharArray.includes(charArray[i]) || keywordCharArray.includes(charArray[i].toLowerCase())) {
        htmlStr += `<span class="highlight">${charArray[i]}</span>`
      } else {
        htmlStr += charArray[i]
      }
    }
    return htmlStr
  } else {
    return str
  }
}
