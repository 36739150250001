import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { isPC } from '@/utils'

const HomeView = () => (isPC ? () => import('@/views/pc/HomeView.vue') : () => import('@/views/h5/HomeView.vue'))

const GuestView = () =>
  isPC
    ? () => import(/* webpackPreload: true */ '@/views/pc/GuestView.vue')
    : () => import(/* webpackPreload: true */ '@/views/h5/GuestView.vue')

const NewsView = () => (isPC ? () => import('@/views/pc/NewsView.vue') : () => import('@/views/h5/NewsView.vue'))

const AllGuest = () => import(/* webpackPreload: true */ '@/components/AllGuest.vue')

const GuestSearch = () => import(/* webpackPreload: true */ '@/components/GuestSearch.vue')

Vue.use(VueRouter)

const routes_pc: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView(),
    children: [
      {
        path: '/guest',
        name: 'Guest',
        component: GuestView(),
        children: [
          {
            path: '/guest/all',
            name: 'All',
            component: AllGuest
          },
          {
            path: '/guest/search',
            name: 'Search',
            component: GuestSearch
          },
          {
            path: '/guest/:page*',
            redirect: '/guest/all'
          }
        ]
      },
      {
        path: '/news',
        name: 'News',
        component: NewsView()
      }
    ]
  },
  {
    path: '/:page*',
    redirect: '/'
  }
]

const routes_h5: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView()
  },
  {
    path: '/guest',
    name: 'Guest',
    component: GuestView(),
    children: [
      {
        path: '/',
        name: 'All',
        component: AllGuest
      },
      {
        path: '/guest/search',
        name: 'Search',
        component: GuestSearch
      },
      {
        path: '/guest/:page*',
        redirect: '/guest'
      }
    ]
  },
  {
    path: '/news',
    name: 'News',
    component: NewsView()
  },
  {
    path: '/:page*',
    redirect: '/'
  }
]

const routes: Array<RouteConfig> = isPC ? routes_pc : routes_h5

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Home' || !!from.name) {
    next()
  } else {
    next('/')
  }
})

export default router
