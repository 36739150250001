import { isPC } from '@/utils'

export const initSvgIconAssets = () => {
  try {
    let request: __WebpackModuleApi.RequireContext = isPC
      ? require.context('@/assets/pc/icons', false, /\.svg$/)
      : require.context('@/assets/h5/icons', false, /\.svg$/)
    request.keys().forEach(request)
    request = require.context('@/assets/common/icons', false, /\.svg$/)
    request.keys().forEach(request)
  } catch (err) {
    console.log(err)
  }
}
