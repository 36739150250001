import { fetchDataBySourceId } from '@/api'
import { newsSourceId } from '@/constants'
import { News, RawNews } from '@/constants/types'
import store from '@/store'

const tranRawList = (rawList: RawNews[]) => {
  const list: News[] = []
  rawList.forEach((item) => {
    const { title, cover, url, is_choice, zoning } = item.properties
    list.push({
      title,
      url,
      is_choice,
      zoning,
      cover: cover.url
    })
  })
  return list
}

const newsFetchList = []
for (let i = 1; i <= 5; i++) {
  newsFetchList.push(fetchDataBySourceId(newsSourceId, i))
}
try {
  Promise.all(newsFetchList).then((values) => {
    const rawList: RawNews[] = []
    values.forEach((value) => {
      const list = value?.data?.list
      if (list) {
        rawList.push(...list)
      }
    })
    const list = tranRawList(rawList)
    store.commit('setAllNewsList', list)
  })
} catch {
  //
}
