import { fetchDataBySourceId } from '@/api'
import { sponsorSourceId } from '@/constants'
import { RawSponsor, Sponsor } from '@/constants/types'
import store from '@/store'

const tranRawList = (rawList: RawSponsor[]) => {
  const list: Sponsor[] = []
  rawList.forEach((item) => {
    const { cover, url, id } = item.properties
    list.push({
      url,
      cover: cover.url,
      id
    })
  })
  return list
}

fetchDataBySourceId(sponsorSourceId).then((res) => {
  const rawList = res?.data?.list ?? []
  if (rawList.length) {
    const list = tranRawList(rawList)
    store.commit('setSponsorList', list)
  }
})
